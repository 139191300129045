.single-slider {
    background-image: url('./img/slider/slider-1.png');
    background-position: center center;
}
.item-1{
    background-image:url('./img/slider/nav/slider-nav-4.jpg')
    
}
.item-2{
    background-image:url('./img/slider/nav/slider-nav-2.jpg')
}
.item-3{
    background-image:url('./img/slider/nav/slider-nav-1.jpg')
}
.item-4{
    background-image:url('./img/slider/nav/slider-nav-3.jpg')
}
.slider__nav-item.item-1::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(225, 247, 30, 0.8);

}
.slider__nav-item.item-3::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(1 52 123 / 81%);

}
.slider__nav-item.green-bg::after{
    background: rgb(79 0 59 / 70%);

}
@media  only screen and (max-width: 900px) {
    .content{
      margin-top: 70px !important;
      text-align: center;
    }
   .titleform{
       font-size: calc(1.275rem + .3vw);
   }
  }
  .swiper-wrapper{
      min-height: 130px;
  }